// https://mui.com/material-ui/react-switch/

import * as React from 'react'

import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import USD from './usd.svg'
import CAD from './cad.svg'
import { useShoppingCart } from '../../context/ShoppingCartContext'
// import { useLocalStorage } from 'hooks/useLocalStorage'

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,

    '& .MuiSwitch-switchBase': {
        margin: 3,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url(${CAD})`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#aab4be',
                ...theme.applyStyles('dark', {
                    backgroundColor: '#8796A5',
                }),
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#001e3c',
        width: 28,
        height: 28,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${USD})`,
        },
        ...theme.applyStyles('dark', {
            backgroundColor: '#003892',
        }),
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be',
        borderRadius: 20 / 2,
        ...theme.applyStyles('dark', {
            backgroundColor: '#8796A5',
        }),
    },
}))
// TODO: 12/19/24 tab controls?
const ToggleCurrency = () => {
    // const [checked, setChecked] = React.useState(false)
    const { isCAD, setIsCADCurrency } = useShoppingCart()

    const handleChange = (event) => {
        setIsCADCurrency(event.target.checked)
    }

    return (
        <FormGroup sx={{ ml: 0 }}>
            <FormControlLabel
                checked={isCAD}
                onChange={handleChange}
                sx={{ mr: 0, color: 'black' }}
                labelPlacement="end"
                label={isCAD ? '$ CAD' : '$ USD'}
                control={<MaterialUISwitch sx={{ mr: 0 }} />}
            />
        </FormGroup>
    )
}

export default ToggleCurrency
