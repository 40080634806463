import React from 'react'
import {
    ListItemText,
    Stack,
    Paper,
    Typography,
    Link,
    useMediaQuery,
} from '@mui/material'
import { AppbarContainer, MyList } from '../../styles/appbar'
import Actions from './actions'
import { Colors } from '../../styles/theme'
import { Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

const sx_listItemAlign = { textAlign: 'center' }

export default function AppbarDesktop({ matches }) {
    const theme = useTheme()
    const matches2 = useMediaQuery(theme.breakpoints.down('lg')) // < 1200
    const estLabel = matches2 ? 'Estimator' : 'Fence Materials Estimator'
    return (
        <AppbarContainer>
            {/* <AppbarHeader variant="h4">ElectroBraid</AppbarHeader> */}
            {/* <AppbarImage src="/shop/images/appbar/logo.png" /> */}
            <Stack direction="row">
                <Paper elevation={2} sx={{ mt: 2, mb: 3, ml: -1 }}>
                    <img src="/shop/images/appbar/logo.png" />
                </Paper>
            </Stack>
            <MyList type="row">
                {/* <ListItemText  primary="Home" /> */}

                <ListItemText sx={sx_listItemAlign}>
                    <Typography lineHeight={2} variant="body1">
                        <Link
                            href="https://www.electrobraid.com/"
                            color={Colors.shaft}
                            underline="none"
                            aria-label="Visit front page"
                        >
                            Home
                        </Link>
                    </Typography>
                </ListItemText>
                <ListItemText sx={sx_listItemAlign}>
                    <Typography lineHeight={2} variant="body1">
                        <Link
                            href="https://www.electrobraid.com/calc"
                            color={Colors.shaft}
                            underline="none"
                            aria-label="Use the Fence Materials Estimator App"
                        >
                            {estLabel}
                        </Link>
                    </Typography>
                </ListItemText>

                <ListItemText sx={sx_listItemAlign}>
                    {/* <a href="#products">Products</a>*/}
                    <Typography lineHeight={2} variant="body1">
                        <Link
                            component={RouterLink}
                            to="/"
                            color={Colors.shaft}
                            underline="none"
                            aria-label="Visit Products section"
                        >
                            Products
                        </Link>

                        {/* #example {
  scroll-behavior: smooth;
} */}
                    </Typography>
                </ListItemText>
                {/* <ListItemText sx={sx_listItemAlign} primary="About us" /> */}

                <ListItemText sx={sx_listItemAlign}>
                    <Typography lineHeight={2} variant="body1">
                        <Link
                            component={RouterLink}
                            to="/info/contact_us"
                            color={Colors.shaft}
                            underline="none"
                            aria-label="How to Contact Us"
                        >
                            Contact Us
                        </Link>
                    </Typography>
                </ListItemText>

                {/* <ListItemButton onClick={() => setShowSearchBox(true)}>
                    <ListItemIcon>
                        <SearchIcon />
                    </ListItemIcon>
                </ListItemButton> */}
            </MyList>
            <Actions matches={matches} />
        </AppbarContainer>
    )
}
