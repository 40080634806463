import React from 'react'
import { Typography } from '@mui/material'
import { ProductMetaWrapper } from '../../styles/product'
import CorrectCopyright from '../CorrectCopyright.js'

export default function ProductMeta({ product }) {
    return (
        <ProductMetaWrapper>
            <Typography variant="h6" sx={{ my: 2, textAlign: 'center' }}>
                <CorrectCopyright
                    copyrightTextValue={product.descriptionTitle}
                />
            </Typography>
        </ProductMetaWrapper>
    )
}
