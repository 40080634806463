import React from 'react'
import { Stack } from '@mui/material'
import { Item } from './Item.js'
import { appUtilities } from '../utilities/appUtilities.js'
export default function PageFooter() {
    const copyright = '\u{00A9}'
    const regTrademark = appUtilities.regTrademark

    const currentDate = new Date()
    const fullYear = currentDate.getFullYear()
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 1, mb: 1 }}
        >
            <Stack>
                <Item>
                    {copyright} {fullYear}
                    <b> ElectroBraid</b>
                    {regTrademark}
                    <b> Fence - </b>
                    All rights reserved.
                </Item>
            </Stack>
        </Stack>
    )
}
