import React, { createContext, useContext, useState } from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage'
// TODO: 04/21/24 build a retreival of onHandQty maintained by aws
// import useAxios from '../hooks/useAxios.js'
// import { config } from '../utilities/constants'
import { products } from '../data'
const ShoppingCartContext = createContext({})

export function useShoppingCart() {
    return useContext(ShoppingCartContext)
}

export function ShoppingCartProvider({ children }) {
    const [showCart, setShowCart] = useState(false)
    // const [isCAD, setIsCADCurrency] = useState(false)

    const [cart, setCart] = useLocalStorage('shopping-cart', [])
    const [dev_mode, _] = useLocalStorage('dev_mode', false)
    const [isCAD, setIsCADCurrency] = useLocalStorage('isCAD', false)

    const data = products[isCAD ? 1 : 0] // grabbing conetnts of ../data/index.js which exports the fence_inv list
    const cartQty = cart.reduce((quantity, item) => item.quantity + quantity, 0)

    function getItemQty(productIDNum) {
        return (
            cart.find((item) => item.productIDNum === productIDNum)?.quantity ||
            0
        )
    }
    function incrItemQty(productIDNum) {
        setCart((currItems) => {
            if (
                currItems.find((item) => item.productIDNum === productIDNum) ==
                null
            ) {
                return [...currItems, { productIDNum, quantity: 1 }]
            } else {
                return currItems.map((item) => {
                    if (item.productIDNum === productIDNum) {
                        return { ...item, quantity: item.quantity + 1 }
                    } else {
                        return item
                    }
                })
            }
        })
    }
    function decrItemQty(productIDNum) {
        setCart((currItems) => {
            if (
                currItems.find((item) => item.productIDNum === productIDNum)
                    ?.quantity === 1
            ) {
                return currItems.filter(
                    (item) => item.productIDNum !== productIDNum,
                )
            } else {
                return currItems.map((item) => {
                    if (item.productIDNum === productIDNum) {
                        return { ...item, quantity: item.quantity - 1 }
                    } else {
                        return item
                    }
                })
            }
        })
    }
    function removeFromCart(productIDNum) {
        setCart((currItems) => {
            return currItems.filter(
                (item) => item.productIDNum !== productIDNum,
            )
        })
    }
    function setItemQty(productIDNum, qty) {
        setCart((currItems) => {
            if (
                currItems.find((item) => item.productIDNum === productIDNum) ==
                null
            ) {
                return [...currItems, { productIDNum, quantity: qty }]
            } else {
                return currItems.map((item) => {
                    if (item.productIDNum === productIDNum) {
                        return { ...item, quantity: qty }
                    } else {
                        return item
                    }
                })
            }
        })
    }
    function emptyCart() {
        setCart([])
    }

    return (
        <ShoppingCartContext.Provider
            value={{
                getItemQty,
                setItemQty,
                incrItemQty,
                decrItemQty,
                removeFromCart,
                emptyCart,
                showCart,
                setShowCart,
                cart,
                cartQty,
                isCAD,
                setIsCADCurrency,
                data,
                dev_mode,
            }}
        >
            {children}
        </ShoppingCartContext.Provider>
    )
}
