import React from 'react'

export default function CorrectCopyright({ copyrightTextValue }) {
    const cpywrit = '®'
    const twoParts = copyrightTextValue.split(cpywrit)
    if (twoParts.length === 1) {
        return twoParts[0]
    }
    if (twoParts.length > 2) {
        throw new Error('more than one regTrademark')
    }

    return (
        <>
            {twoParts[0]}
            <sup style={{ fontSize: '0.65em', top: '-0.8em' }}>{'\u00AE'}</sup>
            {twoParts[1]}
        </>
    )
}
