import React from 'react'
import {
    Dialog,
    DialogTitle,
    Slide,
    Box,
    IconButton,
    DialogContent,
    Typography,
    Button,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { Colors } from '../../styles/theme'
import styled from '@emotion/styled'
import { Product, ProductImage } from '../../styles/product'

import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { config } from '../../utilities/constants'
import CorrectCopyright from '../CorrectCopyright.js'

function SlideTransition(props) {
    return <Slide direction="down" {...props} />
}

const ProductDetailWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(4),
}))

const ProductDetailInfoWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
    lineHeight: 1.5,
}))

export default function ProductDetail({ open, onClose, product }) {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const image = product.href
    const imagePath = config.img_path

    return (
        <Dialog
            TransitionComponent={SlideTransition}
            variant="permanant"
            open={open}
            maxWidth="md"
        >
            <DialogTitle
                sx={{
                    background: Colors.secondary,
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'space-between'}
                    sx={{ color: Colors.warning }}
                >
                    <span>
                        <CorrectCopyright
                            copyrightTextValue={product.descriptionTitle}
                        />
                    </span>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <ProductDetailWrapper
                    display={'flex'}
                    flexDirection={matches ? 'column' : 'row'}
                >
                    <Product sx={{ mr: 4 }}>
                        <ProductImage src={`${imagePath}${image}.jpg`} />
                    </Product>
                    <ProductDetailInfoWrapper>
                        <Typography variant="caption text">
                            {`Product ID: ${product.productID} `}
                        </Typography>

                        <Typography sx={{ mt: 1 }} variant="h5">
                            <CorrectCopyright
                                copyrightTextValue={product.descriptionTitle}
                            />
                            {/* {product.descriptionTitle} */}
                        </Typography>
                        <Typography variant="caption text">
                            {product.unitLabel}
                        </Typography>
                        <Typography sx={{ mt: 1 }} variant="subtitle1">
                            <CorrectCopyright
                                copyrightTextValue={product.description}
                            />
                        </Typography>
                        <Typography sx={{ mt: 1 }} variant="subtitle1">
                            <CorrectCopyright
                                copyrightTextValue={product.featureList}
                            />
                            {/* {product.featureList} */}
                        </Typography>

                        <Box
                            sx={{ mt: 4 }}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Button
                                onClick={onClose}
                                variant="contained"
                                sx={{ ml: 0.5 }}
                            >
                                Continue Shopping
                            </Button>
                        </Box>
                    </ProductDetailInfoWrapper>
                </ProductDetailWrapper>
            </DialogContent>
        </Dialog>
    )
}
